import React from 'react'

import 'react-circular-progressbar/dist/styles.css';

import {Link} from 'react-router-dom'

const PrivcyPolicy = () => {
  return (
    <div>

<div class="breadcumb-area d-flex align-items-center">
	<div class="container">
		<div class="row d-flex align-items-center">
			<div class="col-lg-12">
				<div class="breadcumb-content">
					<h1> Privacy Policy for Ang Solution</h1>
					<ul>
						<li><Link to="index-2.html">Home</Link></li>
						<li> Our Privacy Policy </li>
					</ul>
				</div>
			</div>
			<div class="britcam-shape">
				<div class="breadcumb-content upp">
					<ul>
						<li><Link to="index-2.html">Home</Link></li>
						<li> Our Privacy Policy </li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>


<div class='container'>
<div class='row'>
   <div class='col-12'>
<h3 class='mt-5'>Privacy Policy for Ang Solution</h3>

<p class='my-5'>At Ang Solution, we take your privacy seriously. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our website and IT services. By using our website, you agree to the practices described in this policy.
<br/><br/>

1. Information We Collect
<br/><br/>

We collect various types of information to provide and improve our services:

Personal Information: This may include your name, email address, phone number, company details, and payment information when you fill out forms or make transactions.

Usage Data: We collect information on how you interact with our website, such as your IP address, browser type, pages visited, and the time spent on our site.

Cookies and Tracking Data: Our website uses cookies to improve your experience. Cookies help us track your preferences, remember your login details, and provide personalized content.
<br/><br/>

2. How We Use Your Information
<br/><br/>

The information we collect is used in the following ways:

To Provide Services: We use your personal data to deliver the services you request, process payments, and manage your account.

To Improve Our Services: Usage data helps us understand how customers interact with our website, allowing us to enhance the user experience.

To Communicate with You: We may use your contact information to send important updates, service notifications, or marketing materials if you’ve opted in.

To Ensure Security: We use data to monitor and prevent fraud, protect against unauthorized access, and improve the security of our systems.
<br/><br/>

3. Data Sharing and Disclosure
<br/><br/>

We value your privacy and do not sell or share your personal information with third parties, except in the following cases:

With Your Consent: We will share your information with third parties only if you give us explicit permission.

For Legal Requirements: We may disclose your personal information if required to do so by law or in response to a valid legal request by public authorities.

Service Providers: We may share your data with trusted third-party vendors or partners who assist us in delivering services, such as payment processors or hosting providers. These parties are required to maintain the confidentiality of your information and are not allowed to use it for other purposes.
<br/><br/>

4. Data Security
<br/><br/>

We implement industry-standard security measures to protect your personal data from unauthorized access, disclosure, or destruction. While no system can be entirely secure, we take all necessary precautions to safeguard your information.
<br/><br/>

5. Your Rights
<br/><br/>

You have the following rights regarding your personal data:

Access: You can request to see the personal information we hold about you.

Correction: You have the right to update or correct any inaccurate information.

Deletion: You can request the deletion of your personal data, subject to legal or contractual obligations.

Opt-Out of Marketing: You may unsubscribe from our marketing communications at any time by following the instructions in the emails or contacting us directly.
<br/><br/>

6. Data Retention
<br/><br/>

We retain your personal data only as long as necessary to fulfill the purposes for which it was collected or to comply with legal obligations. After that, we will securely delete or anonymize your data.
<br/><br/>

7. Third-Party Links
<br/><br/>

Our website may contain links to third-party sites. We are not responsible for the privacy practices of these websites. We encourage you to review their privacy policies before providing them with any personal information.
<br/><br/>

8. Changes to this Privacy Policy
<br/><br/>

We may update this Privacy Policy from time to time. Any changes will be posted on this page, and you are encouraged to review it periodically. Your continued use of our website after any changes constitutes acceptance of the new policy.</p>

    </div> 

</div>
</div>

    </div>
  )
}

export default PrivcyPolicy