import React, { useState } from 'react'
import Img1 from '../components/assets/images/resource/abouts_1.png'
import Img2 from '../components/assets/images/resource/abouts_2.png'
import Img3 from '../components/assets/images/resource/img-1.png'
import Img4 from '../components/assets/images/resource/img-2.png'
import Img5 from '../components/assets/images/resource/img-3.png'
import Img6 from '../components/assets/images/resource/testi1.png'
import Img7 from '../components/assets/images/resource/testi3.png'
import Img8 from '../components/assets/images/resource/testi4.png'
import Img13 from '../components/assets/images/resource/all-shape5.png'
import Img14 from '../components/assets/images/resource/testimonial-map.png'
import Img18 from '../components/img/cblsoltionslogo.png'
import Img19 from '../components/img/SDISOLUTION.png'
import Img20 from '../components/img/thunder-shop-logo-design-electric-shop-or-fast-shop-logo-shopping-bag-combined-with-energy-or-lightning-bolt-icon-vector.png'
import Img21 from '../components/img/istockphoto-1181906881-612x612.png'
import Img22 from '../components/img/nazreen employ img/20221027_222630-removebg-preview.png'
import Img23 from '../components/img/nazreen employ img/banner-01.png'
import Img26 from '../components/img/nazreen employ img/WhatsApp Image 2023-05-30 at 2.22.32 PM.png'
import Img32 from '../components/img/nazreen employ img/WhatsApp Image 2023-05-321 at 2.22.32 PM.png'
import Img33 from '../components/img/nazreen employ img/balibahi2.png'
import { VscSettingsGear } from 'react-icons/vsc'
import { BsFacebook } from 'react-icons/bs'
import { BsTwitter } from 'react-icons/bs'
import { BsDribbble } from 'react-icons/bs'
import { BsInstagram } from 'react-icons/bs'
import { BsArrowRightCircle } from 'react-icons/bs'
import { Link } from 'react-router-dom'

const Aboutus = () => {
	const [toggleState, setToggleState] = useState(1);

	const toggleTab = (index) => {
		setToggleState(index);
	};
	return (
		<div><div class="breadcumb-area d-flex align-items-center">
			<div class="container">
				<div class="row d-flex align-items-center">
					<div class="col-lg-12">
						<div class="breadcumb-content">
							<h1>About Us</h1>
							<ul>
								<li><Link to="index-2.html">Home</Link></li>
								<li>About Us </li>
							</ul>
						</div>
					</div>
					<div class="britcam-shape">
						<div class="breadcumb-content upp">
							<ul>
								<li><Link to="index-2.html">Home</Link></li>
								<li>About Us </li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>


			<div class="abouts_areas">
				<div class="container">
					<div class="row">
						<div class="col-lg-6 col-md-6">
							<div class="singles_abouts_boxs">

								<div class="abouts_thumb">
									<img src={Img1} alt="" />
									<div class="about-inner-content">
										<div class="abouts_titles">
											<h3>ALL-IN-ONE .2x WEB <br /> SOLUTION FOR YOUR <br /> IT BUSINESS </h3>
											<div class="abouts_icons">
												<Link to="#"><i class="fas fa-angle-right"></i></Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6 col-md-6">
							<div class="singles_abouts_boxs">
								<div class="abouts_thumb">
									<img src={Img2} alt="" />
									<div class="abouts_titles upper">
										<h3>The top Best Digital <br /> Agency in new Road <br /> Market, USA. </h3>
										<div class="abouts_icons">
											<Link to="#"><i class="fas fa-angle-right"></i></Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>



			<div class="feture-area">
				<div class="container">
					<div class="row">
						<div class="col-lg-6 col-md-6">
							<div class="itsoft-section-title">
								<h5> About </h5>
								<h2> We Are Global Stakeholder Over 2000+  <span> Companies </span></h2>
								<p class="about-text1"> At Ang Solution, we're your IT partners dedicated to innovation and excellence. Our expert team provides tailored IT solutions, from software development to technology consulting. With a commitment to your success, we prioritize your unique needs, ensuring we exceed your objectives. Discover how we can help you achieve your IT goals. </p>
							</div>
							<div class="dreamit-icon-box">
								<div class="dreamit-icon-list">
									<ul>
										<li><BsArrowRightCircle /> <span> Technologies Expertise And Track Record Of Success</span></li>
										<li><BsArrowRightCircle /> <span> Clients Testimonials And Client-Centric Approach</span></li>
										<li><BsArrowRightCircle /> <span> Commitments To innovation With Quality Assurance And Security </span></li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-lg-6 col-md-6 ">
							<div class="tab">

								<ul class="tabs">

									<li className={toggleState === 1 ? "current" : ""}><a className={toggleState === 1 ? "current" : ""}
									 onClick={() => toggleTab(1)}>
										Full Satisfaction        </a>

									</li>
									<li className={toggleState === 2 ? "current" : ""}><a className={toggleState === 1 ? "current" : ""} onClick={() => toggleTab(2)}
									>
										Expert Team	</a></li>
									<li className={toggleState === 3 ? "current" : ""}><a className={toggleState === 1 ? "current" : ""} onClick={() => toggleTab(3)}
									>
										Mission & Vission</a></li>
								</ul>

								<div class="tab_content">
									<div className={toggleState === 1 ? "content  active-content" : "content"}>
										<div class="tabs_item">
											<img src={Img3} />
											<ul class="tabs-inner-list">
												<li><i class="fas fa-check"></i><span>Understand the clients requriment </span></li>
												<li><i class="fas fa-check"></i><span>Timely support and maintanince </span></li>
												<li><i class="fas fa-check"></i><span>User-centric Design,Quality Assurance</span></li>
												<li><i class="fas fa-check"></i><span>Monitar & Continuous Improvement </span></li>
												<li><i class="fas fa-check"></i><span>Agile Development Approach</span></li>
											</ul>
										</div>
									</div>

									<div
										className={toggleState === 2 ? "content  active-content" : "content"}
									>
										<div class="tabs_item">
											<img src={Img4} />
											<ul class="tabs-inner-list">
												<li><i class="fas fa-check"></i><span>10 Years Experience In Related Field</span></li>
												<li><i class="fas fa-check"></i><span>Give An Exclusive Ideas For Business </span></li>
												<li><i class="fas fa-check"></i><span>24/7 Availability For Clients</span></li>
												<li><i class="fas fa-check"></i><span>Communication In A Polite way </span></li>
												<li><i class="fas fa-check"></i><span>Allways Be Focus On Mission</span></li>
											</ul>
										</div>
									</div>

									<div
										className={toggleState === 3 ? "content  active-content" : "content"}>
										<div class="tabs_item">
											<img src={Img5} />
											<ul class="tabs-inner-list">
												<li><i class="fas fa-check"></i><span>Developed & Deliver Innovative Software</span></li>
												<li><i class="fas fa-check"></i><span>Our Ultimate Goal To Create Trust</span></li>
												<li><i class="fas fa-check"></i><span>Our Focus to deliver work with perfection  </span></li>
												<li><i class="fas fa-check"></i><span>We Always Deliver Work On Time</span></li>
												<li><i class="fas fa-check"></i><span>We Always Try To Improve Our Work</span></li>
											</ul>
										</div>
									</div>



									



								</div>
							</div>
						</div>
					</div>
				</div>
			</div>



			<div class="testimonial-area">
				<div class="container">
					<div class="row">
						<div class="col-lg-7 col-md-6">
							<div class="itsoft-section-title white pb-50">
								<h5> <span>Testimonials</span></h5>
								<h2> Ang Solution Trusted <span> Customers</span></h2>
								<h2> Awesome <span> Reviews </span></h2>
							</div>
						</div>
						<div class="col-lg-5 col-md-6">
							<div class="row">
								<div class="col-lg-6">
									<div class="testi-counter-box upper">
										<div class="testi-counter-title">
											<h2 class="counter"> 1,372 </h2>
											<span>+</span>
											<p> Happy Customers </p>
										</div>
									</div>
								</div>
								<div class="col-lg-6">
									<div class="testi-counter-box">
										<div class="testi-counter-title">
											<h2 class="counter"> 100 </h2>
											<span>%</span>
											<p> Satisfaction Rate </p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="container">
						<div class="testimonial_list row">
						<div class="col-lg-4 pr-1">
								<div class="testimonial-single-box">
									<div class="testimonial-content1">
										<div class="single-quote-icon">
											<div class="quote-thumb">
												<img src={Img8} alt="" />
											</div>
											<div class="quote-title">
												<h4> David Alexon </h4>
												<p> MH Manager </p>
											</div>
										</div>
										<div class="em-testimonial-text">
											<p>“Their ability to think through issues and come up with solutions to problems as they arise impresses us”.</p>
										</div>
										<div class="em-testi-start-icon">
											<i class="bi bi-star-fill"></i>
											<i class="bi bi-star-fill"></i>
											<i class="bi bi-star-fill"></i>
											<i class="bi bi-star-fill"></i>
											<i class="bi bi-star-half"></i>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4 pr-1">
								<div class="testimonial-single-box">
									<div class="testimonial-content1">
										<div class="single-quote-icon">
											<div class="quote-thumb">
												<img src={Img6} alt="" />
											</div>
											<div class="quote-title">
												<h4>Kathy Bates</h4>
												<p>UI Designer</p>
											</div>
										</div>
										<div class="em-testimonial-text">
											<p>“There were no surprises. I was very impressed, and we’re looking to get more work to Ang Solution”.</p>
										</div>
										<div class="em-testi-start-icon">
											<i class="bi bi-star-fill"></i>
											<i class="bi bi-star-fill"></i>
											<i class="bi bi-star-fill"></i>
											<i class="bi bi-star-fill"></i>
											<i class="bi bi-star-half"></i>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4 pr-1">
								<div class="testimonial-single-box">
									<div class="testimonial-content1">
										<div class="single-quote-icon">
											<div class="quote-thumb">
												<img src={Img7} alt="" />
											</div>
											<div class="quote-title">
												<h4> Elizabeth John </h4>
												<p> CEO, Founder </p>
											</div>
										</div>
										<div class="em-testimonial-text">
											<p>“They're very organized, disciplined, and have the ability to stick to their commitments”.</p>
										</div>
										<div class="em-testi-start-icon">
											<i class="bi bi-star-fill"></i>
											<i class="bi bi-star-fill"></i>
											<i class="bi bi-star-fill"></i>
											<i class="bi bi-star-fill"></i>
											<i class="bi bi-star-half"></i>
										</div>
									</div>
								</div>
							</div>
						


						</div>
						<div class="testi-shape">
							<div class="testi-shape-thumb">
								<img src={Img13} alt="" />
							</div>
							<div class="testi-shape-thumb1">
								<img src={Img14} alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>


			<div class="counter-section style-33 pt-30 pb-70">
				<div class="container">
					<div class="counter-container">
						<div class="row hr pt-40">
							<div class="col-md-6 col-lg-4">
								<div class="counter-single-item-inner d-flex">
									<div class="counter-content">
										<div class="counter-text">
											<h1><span class="counter">60</span></h1>
											<span></span>
											<div class="counter-title">
												<h4>Member</h4>
												<h3>Professional</h3>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-6 col-lg-4">
								<div class="counter-single-item-inner d-flex">
									<div class="counter-content">
										<div class="counter-text">
											<h1><span class="counter">1,872</span></h1>
											<span></span>
											<div class="counter-title">
												<h4>Projects</h4>
												<h3>Completed</h3>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-6 col-lg-4">
								<div class="counter-single-item-inner d-flex">
									<div class="counter-content">
										<div class="counter-text">
											<h1><span class="counter">99%</span></h1>
											<span></span>
											<div class="counter-title">
												<h4>Client</h4>
												<h3>Satisfactions</h3>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>




			<div class="faq-sectiions ">
				<div class="container">
					<div class="row">
						<div class="col-md-6 col-lg-6">
							<div class="itsoft-section-title white pb-1 mb-1">
								<h5><span> process </span></h5>
								<h2> Freequently Asked <span> Question </span></h2>
								<p class="study-text1"> Appropriately enhance principle-centered innovation rather than high standards in platforms. Credibly orchestrate functional. </p>
							</div>
							<div class="dreamit-icon-box">
								<div class="dreamit-icon-list">
									<ul>
										<li><i class="bi bi-arrow-right-circle"></i> <span> Technologies Expertise </span></li>
										<li><i class="bi bi-arrow-right-circle"></i> <span> Track Record Of Success </span></li>
										<li><i class="bi bi-arrow-right-circle"></i> <span> Use our powerful admin system to manage your task
 </span></li>
										<li><i class="bi bi-arrow-right-circle"></i> <span>We take pride in creative design that is never lost in translation
 </span></li>
									</ul>
								</div>
							</div>
							<div class="study-button">
								<Link to="/Contact"> <VscSettingsGear /> More About </Link>
							</div>
						</div>
						<div class="col-lg-6 col-md-6 pl-0">
							<div class="tab_container pl-30 pt-20">

								<div id="tab1" class="tab_content">
									<ul class="accordion">
										<li>

											<a class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne"><span> What services does your software house company provide? </span></a>
											<div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
												<div class="accordion-body text-white p-3">We provide a wide range of software development services, including custom software development, web application development, mobile app development, software testing and quality assurance, UI/UX design,eCommerce, software maintenance and Customer support, and integration services.</div>

											</div>
										</li>
							
										<li>
											<a class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree"><span> What technologies and programming languages does your team work with? </span></a>
											<div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
												<div class="accordion-body text-white p-3">Our team is proficient in a wide range of technologies and programming languages, including but not limited to Java, Python, JavaScript, .NET, PHP, Ruby on Rails, Angular, React, Node.js, and mobile platforms such as iOS and Android.</div>
											</div>	                        </li>
											<li>
											<a class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo"><span> What is the typical process of working with your software house company? </span></a>
											<div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
												<div class="accordion-body text-white p-3">Our typical process involves several stages, including requirements gathering and analysis, project planning, design and development, quality assurance testing, deployment, and ongoing support. We follow an agile methodology, which allows for flexibility, collaboration, and iterative development throughout the project lifecycle.</div>

											</div>	                        </li>
										<li>
											<a class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour"><span>Can you customize software solutions to meet our specific business needs?</span></a>
											<div id="flush-collapseFour" class="accordion-collapse collapse " data-bs-parent="#accordionFlushExample">
												<div class="accordion-body text-white p-3">Absolutely! We understand that each business has unique requirements, and we specialize in customizing software solutions to meet those specific needs. Our team will work closely with you to understand your requirements and tailor the solution accordingly.</div>
											</div>		                        </li>
											<li>
											<a class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour"><span>What is the estimated timeline for developing a software solution?</span></a>
											<div id="flush-collapseFour" class="accordion-collapse collapse " data-bs-parent="#accordionFlushExample">
												<div class="accordion-body text-white p-3">The timeline for developing a software solution varies depending on the complexity and scope of the project. During the initial stages, we work with you to define clear milestones and provide an estimated timeline. We strive to deliver projects within the agreed-upon timeframe while maintaining high quality.</div>
											</div>		                        </li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>



			<div class="brand-section">
				<div class="brand-list ">
					<div class="container">
						<div class="row">
							<div class=" col-6 col-lg-3">
								<div class="brand-single-box">
									<div class="brand-thumb">
										<img src={Img18} alt="" class='brandlogoimg' />
									</div>
								</div>
							</div>
							<div class="col-6 col-lg-3">
								<div class="brand-single-box">
									<div class="brand-thumb">
										<img src={Img19} alt="" class='brandlogoimg'/>
									</div>
								</div>
							</div>
							<div class="col-6 col-lg-3">
								<div class="brand-single-box">
									<div class="brand-thumb">
										<img src={Img20} alt="" class='brandlogoimg'/>
									</div>
								</div>
							</div>
							<div class="col-6 col-lg-3">
								<div class="brand-single-box">
									<div class="brand-thumb">
										<img src={Img21} alt="" class='brandlogoimg'/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	)
}

export default Aboutus