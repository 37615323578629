import React from 'react'
import Img1 from '../components/assets/images/callicon.png'
import Img2 from '../components/assets/images/logodesignicon.png'
import Img3 from '../components/assets/images/uiuxicon.png'
import Img4 from '../components/assets/images/3dlogoicon.png'
import Img5 from '../components/assets/images/webdesignicon.png'
import Img6 from '../components/assets/images/appicon.png'
import Img7 from '../components/assets/images/customicon.png'
import Img8 from '../components/assets/images/resource/main1.png'
import Img9 from '../components/assets/images/resource/main3.png'
import Img10 from '../components/assets/images/resource/main2.png'
import Img11 from '../components/img/cblsoltionslogo.png'
import Img12 from '../components/img/SDISOLUTION.png'
import Img13 from '../components/img/istockphoto-1181906881-612x612.png'
import Img14 from '../components/img/thunder-shop-logo-design-electric-shop-or-fast-shop-logo-shopping-bag-combined-with-energy-or-lightning-bolt-icon-vector.png'
import {BiPlus} from 'react-icons/bi'
import {BsArrowRightCircle} from 'react-icons/bs'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {VscSettingsGear} from 'react-icons/vsc'
import {BiPauseCircle} from 'react-icons/bi'
import {Link} from 'react-router-dom'

const Service = () => {
    const percentage = 95;


  return (
    <div>
        <div class="breadcumb-area d-flex align-items-center">
	<div class="container">
		<div class="row d-flex align-items-center">
			<div class="col-lg-12">
				<div class="breadcumb-content">
					<h1> Our Packages </h1>
					<ul>
						<li><Link to="index-2.html">Home</Link></li>
						<li> Our Packages </li>
					</ul>
				</div>
			</div>
			<div class="britcam-shape">
				<div class="breadcumb-content upp">
					<ul>
						<li><Link to="index-2.html">Home</Link></li>
						<li> Our Packages </li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>




<div class="service-area">
	<div class="container">
		<div class="row">
			<div class="col-lg-7 col-md-6">
				<div class="itsoft-section-title pb-90">
					<h2> We Run All Kinds Of Packages From <span> Technologies </span></h2>
				</div>
			</div>
			<div class="col-lg-5 col-md-6">
				<div class="itsoft-button text-right pt-20">
					<Link to="/Service"> All Packages <BiPlus/> </Link>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img1} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Consultation <br/> (Audio Call)</h2>
						</div>
						<div class="service-number">
							<h1> 01 </h1>
						</div>
						<div class="em-service-text">
							<h4> $2.99</h4>
						</div>
						<div class="service-button">
							<Link to="/Contact"> Buy Now <BiPlus/> </Link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img2} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Logo Desiging <br/>(Each Logo)
 </h2>
						</div>
						<div class="service-number">
							<h1> 02 </h1>
						</div>
						<div class="em-service-text">
							<h4>$15.99</h4>
						</div>
						<div class="service-button">
							<Link to="/Contact"> Buy Now <BiPlus/> </Link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img3} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> UI / UX DESIGN <br/>(10 Slides)
 </h2>
						</div>
						<div class="service-number">
							<h1> 03 </h1>
						</div>
						<div class="em-service-text">
							<h4>$45.99</h4>
						</div>
						<div class="service-button">
							<Link to="/Contact"> Buy Now <BiPlus/> </Link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img4} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> 3D Logo Design <br/>(Each Logo)
 </h2>
						</div>
						<div class="service-number">
							<h1> 04 </h1>
						</div>
						<div class="em-service-text">
							<h4>$69.99</h4>
						</div>
						<div class="service-button">
							<Link to="/Contact"> Buy Now <BiPlus/> </Link>
						</div>
					</div>
				</div>
			</div><div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img5} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Basic Web Desiging <br/>(Each Website)

 </h2>
						</div>
						<div class="service-number">
							<h1> 04 </h1>
						</div>
						<div class="em-service-text">
							<h4>$109.99</h4>
						</div>
						<div class="service-button">
							<Link to="/Contact"> Buy Now <BiPlus/> </Link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img5} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Adv Web Designing <br/>(Each Website)

 </h2>
						</div>
						<div class="service-number">
							<h1> 04 </h1>
						</div>
						<div class="em-service-text">
							<h4>$199.99</h4>
						</div>
						<div class="service-button">
							<Link to="/Contact"> Buy Now <BiPlus/> </Link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img5} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Web Development <br/>(3 Pages)

 </h2>
						</div>
						<div class="service-number">
							<h1> 04 </h1>
						</div>
						<div class="em-service-text">
							<h4>$299.99</h4>
						</div>
						<div class="service-button">
							<Link to="/Contact"> Buy Now <BiPlus/> </Link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img5} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Web Development <br/>(6 Pages)

 </h2>
						</div>
						<div class="service-number">
							<h1> 04 </h1>
						</div>
						<div class="em-service-text">
							<h4>$500</h4>
						</div>
						<div class="service-button">
							<Link to="/Contact"> Buy Now <BiPlus/> </Link>
						</div>
					</div>
				</div>
			</div>
            <div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img6} alt=""/>
						</div>
						<div class="em-service-title">
							<h2>App Development <br/>(Basic)

 </h2>
						</div>
						<div class="service-number">
							<h1> 04 </h1>
						</div>
						<div class="em-service-text">
							<h4>$600</h4>
						</div>
						<div class="service-button">
							<Link to="/Contact"> Buy Now <BiPlus/> </Link>
						</div>
					</div>
				</div>
			</div>
            <div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img6} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> App Development <br/>(Advance)

 </h2>
						</div>
						<div class="service-number">
							<h1> 04 </h1>
						</div>
						<div class="em-service-text">
							<h4>$800</h4>
						</div>
						<div class="service-button">
							<Link to="/Contact"> Buy Now <BiPlus/> </Link>
						</div>
					</div>
				</div>
			</div>
            <div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img6} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> App Development <br/>(Complete App)

 </h2>
						</div>
						<div class="service-number">
							<h1> 04 </h1>
						</div>
						<div class="em-service-text">
							<h4>$1000</h4>
						</div>
						<div class="service-button">
							<Link to="/Contact"> Buy Now<BiPlus/> </Link>
						</div>
					</div>
				</div>
			</div>
            <div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img7} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Custom Package <br/>(Your Choice)

 </h2>
						</div>
						<div class="service-number">
							<h1> 04 </h1>
						</div>
						<div class="em-service-text">
							<h4>Contact Us</h4>
						</div>
						<div class="service-button">
							<Link to="/Contact"> Contact <BiPlus/> </Link>
						</div>
					</div>
				</div>
			</div>
			
		</div>
	</div>
</div>




{/* <div class="demo my-4" bis_skin_checked="1">
    <div class="container-fluid  p-md-5" bis_skin_checked="1">
        <div class="row" bis_skin_checked="1">
            <div class="col-md-6 col-sm-12 col-lg-4" bis_skin_checked="1">
                <div class="pricingTable blue" bis_skin_checked="1">
                    <div class="pricingTable-header" bis_skin_checked="1">
                        <h3 class="title">Basic</h3>
                        <div class="price-value" bis_skin_checked="1">
                            <span class="currency">$</span>
                            <span class="amount my-3">399.99/</span>
                            <span class="duration">per month</span>
                        </div>
                    </div>
                    <ul class="pricing-content">
                        <li>Web Development</li>
                        <li>Application Development</li>
                        <li>Product Hunting</li>
                        <li>Product Launching</li>
                        <li>CRM</li>
                    </ul>
                    <div class="pricingTable-signup" bis_skin_checked="1">
                        <Link href="#">Contact Us</Link>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12 col-lg-4" bis_skin_checked="1">
                <div class="pricingTable blue" bis_skin_checked="1">
                    <div class="pricingTable-header" bis_skin_checked="1">
                        <h3 class="title">Standard</h3>
                        <div class="price-value" bis_skin_checked="1">
                            <span class="currency">$</span>
                            <span class="amount">599.99/</span>
                            <span class="duration">per month</span>
                        </div>
                    </div>
                    <ul class="pricing-content">
                        <li>Web Development</li>
                        <li>Application Development</li>
                        <li>Product Hunting</li>
                        <li>Product Launching</li>
                        <li>CRM</li>
						<li>Customer Service (First Year Free)</li>
                        <li>Social Media Handling</li>

                    </ul>
                    <div class="pricingTable-signup" bis_skin_checked="1">
                        <Link href="#">Contact Us</Link>
                    </div>
                </div>
            </div>
			<div class="col-md-6 col-sm-12 col-lg-4" bis_skin_checked="1">
                <div class="pricingTable blue" bis_skin_checked="1">
                    <div class="pricingTable-header" bis_skin_checked="1">
                        <h3 class="title">Premium</h3>
                        <div class="price-value" bis_skin_checked="1">
                            <span class="currency">$</span>
                            <span class="amount">999.99 /</span>
                            <span class="duration">per month</span>
                        </div>
                    </div>
                    <ul class="pricing-content">
                        <li>Web Development</li>
                        <li>Application Development</li>
                        <li>Product Hunting</li>
                        <li>Product Launching</li>
                        <li>CRM</li>
						<li>Customer Service (First Year Free)</li>
                        <li>Social Media Handling</li>
                        <li>Book Keeping</li>
						<li>NFT Creating</li>
                        <li>Web designing (First Year Free)</li>
                        <li>Creating Token If Business Required.</li>
                    </ul>
                    <div class="pricingTable-signup" bis_skin_checked="1">
                        <Link href="#">Contact Us</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> */}


</div>
  )
}

export default Service