import React, { useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import logo from '../components/img/ANGSOLUTON-LOGO.png'
import './Dropdown.css'
import { Link } from 'react-router-dom'
import './Navbar.css'
import Skypeimg from "../components/img/Skype-icon-new.png"
import Whatsappimg from '../components/img/pngwing.com.png'
import Phoneimg from '../components/img/phone.png'

const Navbar = () => {

    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)

    const closeMenu = () => setClick(false)

    const [color, setColor] = useState(false)
    const changeColor = () => {
        if (window.scrollY >= 150) {
            setColor(true)
        } else {
            setColor(false)

        }
    }

    window.addEventListener('scroll', changeColor)

    return (
        <div className={ color ? 'header header-bg' : 'header'}>
            <nav className='navbar'>
                <Link to='/' className='logo'>
                    <img src={logo} alt='logo' />
                </Link>
                <div className='hamburger' onClick={handleClick}>
                    {click ? (<FaTimes size={30} style={{ color: '#ffffff' }} />)
                        : (<FaBars size={30} style={{ color: '#ffffff' }} />)}

                </div>
                <ul className={click ? "nav-menu active" : "nav-menu"}>
                    <li className='nav-item'>
                        <Link to='/' onClick={closeMenu}>Home</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/Aboutus' onClick={closeMenu}>About</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/Service' onClick={closeMenu}>Service</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/Packages' onClick={closeMenu}>Packages</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/Refundpolicy' onClick={closeMenu}>Refund Policy</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/Termscondition' onClick={closeMenu}>Terms & Condition</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/PrivcyPolicy' onClick={closeMenu}>Privcy Policy</Link>
                    </li>
                
                   
                    <li className='nav-item'>
                        <Link to='/Contact' onClick={closeMenu}>Contact</Link>
                    </li>
                  
                    
                </ul>
            </nav>
        </div>
    )
}

export default Navbar
