import React from 'react'

import 'react-circular-progressbar/dist/styles.css';

import {Link} from 'react-router-dom'
const Refundpolicy = () => {
  return (
    <div>

<div class="breadcumb-area d-flex align-items-center">
	<div class="container">
		<div class="row d-flex align-items-center">
			<div class="col-lg-12">
				<div class="breadcumb-content">
					<h1> Our Refund Policy </h1>
					<ul>
						<li><Link to="index-2.html">Home</Link></li>
						<li> Our Refund Policy </li>
					</ul>
				</div>
			</div>
			<div class="britcam-shape">
				<div class="breadcumb-content upp">
					<ul>
						<li><Link to="index-2.html">Home</Link></li>
						<li> Our Refund Policy </li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>


<div class='container'>
<div class='row'>
   <div class='col-12'>
<h3 class='mt-5'>Refund Policy for Ang Solution</h3>
<p class='my-5'>
At Ang Solution, we strive to provide high-quality IT solutions tailored to your business needs. However, we understand that there may be circumstances where a refund is necessary. Please review our refund policy below:
<br/><br/>
1. General Refund Policy
<br/><br/>
We offer refunds under specific conditions, based on the nature of the services provided. Please note that due to the customized nature of our IT solutions, refunds may not always be available once work has commenced.
<br/>
2. Eligibility for Refunds
<br/><br/>
Service Cancellation Before Commencement: If you cancel your service before any work has started, a full refund will be issued, minus any administrative fees incurred.

Inadequate Performance: If the service provided does not meet the agreed specifications or deliverables, we may issue a partial or full refund after reviewing the issue.

No Refunds on Completed Work: Once a project or service is completed and delivered as per the agreed scope, no refunds will be issued.
<br/><br/>
3. Subscription-Based Services
<br/><br/>
For subscription-based services (e.g., hosting or maintenance):

Monthly Subscriptions: Refunds are not available for monthly subscriptions once the billing cycle has started. If you cancel, the service will continue until the end of the billing cycle.

Annual Subscriptions: If you cancel within 14 days of the initial payment, a full refund will be issued. After 14 days, no refunds will be provided.
<br/><br/>
4. Refund Process
<br/><br/>
To request a refund, please contact our support team at support@angsolution.com with your order number, details of the service, and a reason for your refund request.

Refunds will be processed within 7-10 business days after approval, and funds will be credited to the original payment method used.
<br/><br/>
5. Dispute Resolution
<br/><br/>
If there is a disagreement regarding the quality or scope of the services, we encourage customers to reach out to our support team for resolution before requesting a refund. We aim to resolve any issues amicably and to the satisfaction of both parties.


</p>
    </div> 

</div>
</div>
    </div>
  )
}

export default Refundpolicy