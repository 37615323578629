import React from 'react'
import Img1 from '../components/assets/images/resource/small-dot.png'
import Img2 from '../components/assets/images/resource/big-dot.png'
import Img3 from '../components/assets/images/resource/footer1.png'
import Img4 from '../components/assets/images/resource/footer2.png'
import Img5 from '../components/assets/images/resource/red-dot.png'
import Img6 from '../components/assets/images/resource/all-shape.png'
import logo from '../components/img/ANGSOLUTON-LOGO.png'
import {ImFacebook} from 'react-icons/im';
import {FaTwitter} from 'react-icons/fa'
import {BsInstagram} from 'react-icons/bs'
import {FaLinkedinIn} from 'react-icons/fa'
import {Link} from 'react-router-dom'
const Footer = () => {
  return (
    <div><div class="footer-middle"> 
	<div class="container">
		<div class="row subscribe-area">
			<div class="col-lg-6 col-md-6">
				<div class="subscribe-title">
					<h1>Subscribe to our Newsletter</h1>
				</div>
			</div>
			<div class="col-lg-6 col-md-6">
				<form action="https://formspree.io/f/myyleorq" method="POST" id="dreamit-form">
					<div class="subscribe_form">
						<input type="email" name="email" id="email" class="form-control" required data-error="Please enter your email" placeholder="Enter Your Email"/>
						<button type="submit" class="btn">Subscribe</button>
					</div>
					<div class="subscribe_form_send">
						
					</div>
				</form>
				<div id="status"></div>
			</div>
			<div class="subscribe-shape">
				<div class="subscribe-thumb bounce-animate5">
					<img src={Img1} alt=""/>
				</div>
				<div class="subscribe-thumb1">
					<img src={Img2} alt=""/>
				</div>
			</div>
		</div>
		<div class="row footer-bg">
			<div class="col-lg-4 col-md-6">
				<div class="widget widgets-company-info">
					<div class="company-info-desc pr-2">
						<h4 class="widget-title"> About Us </h4>
						<p>Welcome to Ang Solution, a leading software development company dedicated to providing innovative solutions for businesses worldwide. With a team of highly skilled professionals, we specialize in delivering cutting-edge software solutions that drive growth, efficiency, and success for our clients.</p>
						<div class="itsoft-logo">
					<Link class="logo_thumb" to="/" title="itsoft">
						<img src={logo} style={{width:'90px'}} alt="" />
					</Link>
				</div>
					</div>
					
				</div>					
			</div>
			<div class="col-lg-2 col-md-6">
				<div class="widget widget-nav-menu">
					<h4 class="widget-title">Company</h4>
					<div class="menu-quick-link-content">
						<ul class="footer-menu">
							<li><Link to="/"> Home </Link></li>
							<li><Link to="/Aboutus"> About Us</Link></li>
							<li><Link to="/Contact"> Contact Us </Link></li>
							<li><Link to="/Service"> Service </Link></li>
							<li><Link to="/refundpolicy"> Refund Policy </Link></li>
							<li><Link to="/Privacypolicy"> Privacy Policy </Link></li>

						</ul>
					</div>
				</div>
			</div>	
			<div class="col-lg-3 col-md-6 pl-20">
				<div class="widget widget-nav-menu">
					<h4 class="widget-title"> Services </h4>
					<div class="menu-quick-link-content">
						<ul class="footer-menu">
							<li><Link to="/Service"> App Development </Link></li>
							<li><Link to="/Service"> Web Development </Link></li>
							<li><Link to="/Service"> Custom Development </Link></li>
							<li><Link to="/Service"> All Packages </Link></li>
							
							<br/>
						

						

						</ul>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6 pr-0">
				<div id="em-recent-post-widget">
					<div class="single-widget-item">
						<h4 class="widget-title">Popular Posts</h4>				
						<div class="recent-post-item active">
							<div class="recent-post-image">
								<Link to="#">
									<img width="80" height="80" src={Img3} alt=""/>					
								</Link>
							</div>
							<div class="recent-post-text">
								<h6><Link to="#">
									How To Plugin Install WP server?										
									</Link>
								</h6>					
								<span class="rcomment">December 12, 2022</span>
							</div>
						</div>
						<div class="recent-post-item">
							<div class="recent-post-image">
								<Link to="#">
									<img width="80" height="80" src={Img4} alt=""/>					
								</Link>
							</div>
							<div class="recent-post-text">
								<h6><Link to="#">
									Top 10 Most Usefull Google Extansion									
									</Link>
								</h6>					
								<span class="rcomment">December 15, 2022</span>
							</div>
						</div>
					</div>
				</div>	
			</div>
			<div class="foorer-shape">
				<div class="footer-thumb">
					<img src={Img5} alt=""/>
				</div>
				<div class="footer-thumb1 bounce-animate2">
					<img src={Img6} alt=""/>
				</div>
			</div>
		</div>
	</div>
	<div class="container py-5 pb-lg-5">
		<div class="row ">
			
			<div class="col-lg-12 text-center">
				<div class="footer-bottom-content">
					<div class="footer-bottom-content-copy">
						<p>Copyright © 2024 <span>Ang Solution</span> all rights reserved.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	
</div>	</div>
  )
}

export default Footer