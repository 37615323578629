import React from 'react'
import 'react-circular-progressbar/dist/styles.css';
import {Link} from 'react-router-dom'

const Termscondition = () => {
  return (
    <div>

<div class="breadcumb-area d-flex align-items-center">
	<div class="container">
		<div class="row d-flex align-items-center">
			<div class="col-lg-12">
				<div class="breadcumb-content">
					<h1> Our Terms & Condition </h1>
					<ul>
						<li><Link to="index-2.html">Home</Link></li>
						<li> Our Terms & Condition </li>
					</ul>
				</div>
			</div>
			<div class="britcam-shape">
				<div class="breadcumb-content upp">
					<ul>
						<li><Link to="index-2.html">Home</Link></li>
						<li> Our Terms & Condition </li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>


<div class='container'>
<div class='row'>
   <div class='col-12'>
<h3 class='mt-5'>Terms & Conditions for Ang Solution</h3>

<p class='my-5'>
Welcome to Ang Solution! By accessing and using our website and services, you agree to the following Terms & Conditions. Please read these terms carefully as they govern your use of our website and IT services.
<br/><br/>


1. Acceptance of Terms
<br/><br/>

By accessing or using any part of our website or services, you agree to be bound by these Terms & Conditions. If you do not agree to these terms, you must not use our services.
<br/><br/>

2. Services Provided
<br/><br/>

Ang Solution offers a range of IT solutions, including but not limited to software development, web design, IT consultancy, and system integration. The specific scope of services will be outlined in the agreement with each client.

Custom Services: Any custom solutions or services provided will follow the agreed specifications outlined in our service agreements.

Service Availability: While we strive to ensure that our services are available at all times, we do not guarantee uninterrupted access due to maintenance, upgrades, or unforeseen issues.
<br/><br/>

3. Use of Website
<br/><br/>

You agree to use our website in compliance with all applicable laws and regulations. You are prohibited from:

Using the website for any unlawful purposes or engaging in any activity that violates the rights of Ang Solution or others.

Attempting to interfere with or disrupt the website’s functionality, security features, or services.

Copying, distributing, or modifying any part of the content or intellectual property on the website without express written consent from Ang Solution.
<br/><br/>

4. User Accounts
<br/><br/>

To access certain services, you may be required to create an account. You agree to provide accurate, up-to-date information during registration and to keep your account information confidential.

Account Security: You are responsible for maintaining the security of your account credentials. Ang Solution will not be liable for any loss or damage caused by unauthorized access to your account.
<br/><br/>

5. Payment Terms
<br/><br/>

Pricing: The cost of services will be detailed in the specific service agreement provided to each client. All fees are due as outlined in the agreement, and Ang Solution reserves the right to modify pricing for future services.

Late Payments: If payments are not made on time, we reserve the right to suspend services until payment is received.

Refunds: Refunds are subject to our Refund Policy. Please review it for more information.
<br/><br/>

6. Intellectual Property
<br/><br/>

All content, materials, and intellectual property on the website, including logos, graphics, text, software, and designs, are owned by Ang Solution or its licensors. You may not use, reproduce, or distribute any content from our website without express written permission.

License to Use: You are granted a limited, non-exclusive, and non-transferable license to access and use the content for personal or internal business use only.
<br/><br/>

7. Limitation of Liability
<br/><br/>

To the fullest extent permitted by law, Ang Solution is not liable for any indirect, incidental, or consequential damages arising out of or in connection with the use or inability to use our services, including but not limited to loss of data, profits, or business opportunities.

Service Performance: While we strive to deliver high-quality services, Ang Solution does not guarantee that our services will meet all your expectations or that they will be error-free.

Third-Party Services: We may use third-party services or software as part of our solutions. Ang Solution is not liable for the performance or reliability of any third-party products or services.
<br/><br/>

8. Indemnification
<br/><br/>

You agree to indemnify and hold Ang Solution harmless from any claims, damages, liabilities, or expenses arising from your use of our services, violation of these terms, or any unlawful activities conducted through your account.
<br/><br/>

9. Termination
<br/><br/>

Ang Solution reserves the right to terminate or suspend your account and access to services at our sole discretion if you breach these terms or engage in any activities that harm our business or users.

Termination by You: You may terminate your account or cease using our services at any time. Any outstanding fees must still be paid in full.

Termination by Us: We may terminate or suspend services for any violation of these terms without notice. In the event of termination, any fees paid will not be refunded unless otherwise stated in our Refund Policy.
<br/><br/>

10. Changes to Terms
<br/><br/>

Ang Solution reserves the right to update or modify these Terms & Conditions at any time. Any changes will be posted on this page, and your continued use of our services constitutes acceptance of the updated terms.

</p>

    </div> 

</div>
</div>
    </div>
  )
}

export default Termscondition